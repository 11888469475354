var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('l-feature-group',{attrs:{"visible":_vm.visible}},[_c('detection-marker',_vm._b({attrs:{"selected":_vm.selected},on:{"selectDetection":_vm.onSelect}},'detection-marker',Object.assign({}, _vm.detection,
      {activeKey: _vm.activeKey,
      level: ((_vm.cameraRfTrack ? '2-' : '') + "5"),
      state: _vm.cameraRfTrack && _vm.cameraRfTrack[1].state}),false),[_c('detection-popup',_vm._b({attrs:{"slot":"popup","id":"detection-popup","tracking-button":true},slot:"popup"},'detection-popup',Object.assign({}, _vm.detection,
        {direction: _vm.direction,
        distance: _vm.distance,
        eta: _vm.eta},
        _vm.rfDetails,
        {type: 'camera'}),false))],1),(_vm.selected && _vm.theme === 'DroneShield')?_c('detection-polyline',{attrs:{"tail":_vm.liveTail}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }